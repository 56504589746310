import { addArtist, deleteArtist, getArtist, updateArtist } from "../../../services/artist.service";

export const ARTIST_ADD = "ARTIST_ADD";
export const ARTIST_ADD_SUCCESS = "ARTIST_ADD_SUCCESS";
export const ARTIST_ADD_FAIL = "ARTIST_ADD_FAIL";

export const GET_ALL_ARTISTS = "GET_ALL_ARTISTS";
export const GET_ALL_ARTISTS_SUCCESS = "GET_ALL_ARTISTS_SUCCESS";
export const GET_ALL_ARTISTS_FAIL = "GET_ALL_ARTISTS_FAIL";

export const UPDATE_ARTIST_BY_ID = "UPDATE_ARTIST_BY_ID";
export const UPDATE_ARTIST_BY_ID_SUCCESS = "UPDATE_ARTIST_BY_ID_SUCCESS";
export const UPDATE_ARTIST_BY_ID_FAIL = "UPDATE_ARTIST_BY_ID_FAIL";

export const SET_ARTIST_OBJ = "SET_ARTIST_OBJ";
export const SET_ARTIST_OBJ_SUCCESS = "SET_ARTIST_OBJ_SUCCESS";
export const SET_ARTIST_OBJ_FAIL = "SET_ARTIST_OBJ_FAIL";

export const GET_ARTIST_BY_ID = "GET_ARTIST_BY_ID";
export const GET_ARTIST_BY_ID_SUCCESS = "GET_ARTIST_BY_ID_SUCCESS";
export const GET_ARTIST_BY_ID_FAIL = "GET_ARTIST_BY_ID_FAIL";

export const DELETE_ARTIST_BY_ID = "DELETE_ARTIST_BY_ID";
export const DELETE_ARTIST_BY_ID_SUCCESS = "DELETE_ARTIST_BY_ID_SUCCESS";
export const DELETE_ARTIST_BY_ID_FAIL = "DELETE_ARTIST_BY_ID_FAIL";

export const ARTISTAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: ARTIST_ADD });
    let { data: response } = await addArtist(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: ARTIST_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: ARTIST_ADD_FAIL, payload: err });
  }
};

export const ARTISTGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ARTISTS });
    let { data: response } = await getArtist(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_ARTISTS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: ARTIST_ADD_FAIL, payload: err });
  }
};

export const SetARTISTObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_ARTIST_OBJ });
    if (formData) {
      dispatch({
        type: SET_ARTIST_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_ARTIST_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_ARTIST_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const ARTISTUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ARTIST_BY_ID });
    let { data: response } = await updateArtist(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_ARTIST_BY_ID_SUCCESS,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_ARTIST_BY_ID_FAIL, payload: err });
  }
};

export const ARTISTDelete = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ARTIST_BY_ID });
    let { data: response } = await deleteArtist(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: DELETE_ARTIST_BY_ID_SUCCESS,
      });
      ARTISTGet();
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_ARTIST_BY_ID_FAIL, payload: err });
  }
};
