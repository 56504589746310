import { getStockBySellerId, getStocks } from "../../../services/productsStocks.services";

export const GET_PRODUCT_STOCK_BY_SELLER_ID = "GET_PRODUCT_STOCK_BY_SELLER_ID";
export const GET_PRODUCT_STOCK_BY_SELLER_ID_SUCCESS = "GET_PRODUCT_STOCK_BY_SELLER_ID_SUCCESS";
export const GET_PRODUCT_STOCK_BY_SELLER_ID_FAIL = "GET_PRODUCT_STOCK_BY_SELLER_ID_FAIL";

export const getProductStockBySellerId = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_STOCK_BY_SELLER_ID });
    let { data: response } = await getStocks(formData);
    if (response) {
      dispatch({
        type: GET_PRODUCT_STOCK_BY_SELLER_ID_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({ type: GET_PRODUCT_STOCK_BY_SELLER_ID_FAIL, payload: err });
  }
};
