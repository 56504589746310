import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { images } from "../../Images/Images";
import Select from "react-select";

import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";

import { useNavigate, useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import { assignOrderToDelhiveryApi, getInvoiceOrderById, getOrderById, trackOrderToApi, updateOrderById } from "../../../services/order.service";
import { generateFilePath, MainOrderStatus, MainOrderStatusArr, Order_Payment_Status_Filter_Arr } from "../../Utility/utils";
import moment from "moment";
import { url } from "../../../services/url.service";
import { Box, Modal } from "@mui/material";
function SaleDetail() {
  // =========================================================================================

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState({});
  const [selectedPaymentStatusStr, setSelectedPaymentStatusStr] = useState({});
  const [orderObj, setOrderObj] = useState({});
  const [Height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [totalMrp, settotalMrp] = useState(0);
  const [Length, setLength] = useState(0);
  const [trackObj, setTrackObj] = useState({});
  const [isModel, setIsModel] = useState(false);

  const sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width:'80px'
    },
    {
      name: "Image",
      cell: (row) => <img src={generateFilePath(row?.image)} alt={row.name} />,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Variant",
      selector: (row) => row?.variantobj?.name,
    },
    {
      name: "P-Code",
      selector: (row) => row?.productCode,
    },

    {
      name: "Price X Qty",
      selector: (row) => row.price + '  X  ' + row.quantity,
    },
 
 

    {
      name: "Total",
      selector: (row) => row.totalPrice,
    },
  ];

  const sale_data = [
    {
      id: "1",
      Seq: "1",
      img: images.product,
      name: "VELVET MATTE LIPSTICK",
      quantity: "6",
      color: "blue",
      price: "₹ 600.00",
      tax: "₹ 0.00",
      total: "₹ 3,600.00",
    },
  ];

  const order_comfirm = [
    { value: "Pending", label: "Pending" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Declined", label: "Declined" },
  ];
  const payment_status = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
  ];
  const is_complete = [
    { value: "Pending", label: "Pending" },
    { value: "Complete", label: "Complete" },
  ];
  const delivery = [
    { value: "Pending", label: "Pending" },
    { value: "Processing", label: "Processing" },
    { value: "Shipped", label: "Shipped" },
    { value: "Recieved", label: "Recieved" },
    { value: "Delivered", label: "Delivered" },
  ];

  const getOrders = async () => {
    try {
      const { data: res } = await getOrderById(searchParams.get("id"));
      if (res) {
        console.log(res.data);
        setOrderObj(res.data);
        if (res.data.orderStatus) {
          setSelectedOrderStatus({ label: res.data.orderStatus, value: res.data.orderStatus });
        }
        if (res.data.paymentStatusStr) {
          setSelectedPaymentStatusStr({ label: res.data.paymentStatusStr, value: res.data.paymentStatusStr });
        }
        if (res?.data?.Height) {
          setHeight(res?.data?.Height)
        }
        if (res?.data?.width) {
          setHeight(res?.data?.width)
        }
        if (res?.data?.Length) {
          setHeight(res?.data?.Length)
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);



  useEffect(() => {
    if (orderObj && orderObj?.productsArr) {
      let total = 0;
      orderObj?.productsArr.map((el) => {
        total += (el.mrp);
      })

      settotalMrp(total)
    }
  }, [orderObj]);

  const handleOrderStatusUpdate = async () => {
    try {
      let obj = {
        orderStatus: selectedOrderStatus?.value,
        paymentStatusStr: selectedPaymentStatusStr?.value,
      };


      let statusArr = [...orderObj?.statusArr];

      if (statusArr.some((el) => el.status == selectedOrderStatus?.value)) {
        toastError(selectedOrderStatus?.value + " Status Already Updated");
        return 0
      }

      statusArr.push({
        status: selectedOrderStatus?.value,
        on: new Date()
      })

      obj.statusArr = statusArr;
      const { data: res } = await updateOrderById(orderObj?._id, obj);
      if (res) {
        toastSuccess(res.message);
        getOrders();
      }
    } catch (error) {
      toastError(error);
    }
  };
  const handleAssingtoOrder = async () => {
    try {


      // if(orderObj?.orderStatus != MainOrderStatus.DESPATCHED){
      //   toastError("Please Update Order Status " + MainOrderStatus.DESPATCHED);
      //   return 0
      // }
      const { data: res } = await assignOrderToDelhiveryApi(orderObj?._id);
      if (res) {
        toastSuccess(res.message);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleTrackOrder = async () => {
    try {

      const { data: res } = await trackOrderToApi(orderObj?.awbNo);
      if (res.data) {
        toastSuccess(res.message);
        setIsModel(true)
        setTrackObj(res.data)
      }
    } catch (error) {


      toastError(error);
    }
  };
  const handleOrderDimensionsUpdate = async () => {
    try {
      let obj = {
        Height,
        width,
        Length
      };
      const { data: res } = await updateOrderById(orderObj?._id, obj);
      if (res) {
        toastSuccess(res.message);
        getOrders();
      }
    } catch (error) {
      toastError(error);
    }
  };
  const handlegetInvoiceOrderById = async () => {
    try {

      const { data: res } = await getInvoiceOrderById(orderObj?._id);
      if (res) {
        toastSuccess(res.message);
        if (res.data) {
          window.location.href = url + '/' + res.data
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  //   =========================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-between align-items-center mb-4">
              {/* <h5 className="main_text_color m-0">27220617041151</h5> */}
              <CustomButton downloadAble btnName="PRINT" isBtn btntype="button" ClickEvent={() => navigate(`/MainInvoice?orderId=${orderObj?._id}`)} noIcon noIconMargin />
              <CustomButton downloadAble btnName="PRINT" isBtn btntype="button" ClickEvent={() => handlegetInvoiceOrderById(orderObj?._id)} noIcon noIconMargin />
            </div>
          </div>
          <div className="row">
          <DashboardBox className="col-12 col-md-8 row gy-4 m-0">
              <div className="col-12 col-md-6">
                <DashboardBox className="main_text_color">
                  <div className="customer-profile">
                    <h6 className="main_text_color text-capitalize mb-3">Billing Info</h6>
                    <ul className="main_text_color fs-14">
                      <li>
                        <span className="fw-600">
                          Name<span>:</span>
                        </span>
                        {orderObj?.addressObj?.firstName}       {orderObj?.addressObj?.lastName}
                      </li>

                      <li>
                        <span className="fw-600">
                          Phone<span>:</span>
                        </span>
                        {orderObj?.addressObj?.phone}
                      </li>
                      <li>
                        <span className="fw-600">
                          Email<span>:</span>
                        </span>
                        {orderObj?.addressObj?.email}
                      </li>
                      <li>
                        <span className="fw-600">
                          Address<span>:</span>
                        </span>
                        {orderObj?.addressObj?.street}
                      </li>
                      <li>
                        <span className="fw-600">
                          City<span>:</span>
                        </span>
                        {orderObj?.addressObj?.city}
                      </li>
                      <li>
                        <span className="fw-600">
                          State<span>:</span>
                        </span>
                        {orderObj?.addressObj?.state}
                      </li>
                      <li>
                        <span className="fw-600">
                          Pincode<span>:</span>
                        </span>
                        {orderObj?.addressObj?.pincode}
                      </li>
                      <li>
                        <span className="fw-600">
                          Type<span>:</span>
                        </span>
                        {orderObj?.addressObj?.homeType}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-md-6">
                <DashboardBox className="main_text_color">
                  <div className="customer-profile">
                    <h6 className="main_text_color text-capitalize mb-3">Shipping Info</h6>
                    <ul className="main_text_color fs-14">
                      <li>
                        <span className="fw-600">
                          Name<span>:</span>
                        </span>
                        {orderObj?.addressObj?.firstName}       {orderObj?.addressObj?.lastName}
                      </li>

                      <li>
                        <span className="fw-600">
                          Phone<span>:</span>
                        </span>
                        {orderObj?.addressObj?.phone}
                      </li>
                      <li>
                        <span className="fw-600">
                          Email<span>:</span>
                        </span>
                        {orderObj?.addressObj?.email}
                      </li>
                      <li>
                        <span className="fw-600">
                          Address<span>:</span>
                        </span>
                        {orderObj?.addressObj?.street}
                      </li>
                      <li>
                        <span className="fw-600">
                          City<span>:</span>
                        </span>
                        {orderObj?.addressObj?.city}
                      </li>
                      <li>
                        <span className="fw-600">
                          State<span>:</span>
                        </span>
                        {orderObj?.addressObj?.state}
                      </li>
                      <li>
                        <span className="fw-600">
                          Pincode<span>:</span>
                        </span>
                        {orderObj?.addressObj?.pincode}
                      </li>
                      <li>
                        <span className="fw-600">
                          Type<span>:</span>
                        </span>
                        {orderObj?.addressObj?.homeType}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-md-6">
                <DashboardBox className="main_text_color">
                  <div className="customer-profile">
                    <h6 className="main_text_color text-capitalize mb-3">Payment Info</h6>
                    <ul className="main_text_color fs-14">
                      <li>
                        <span className="fw-600">
                          Payment Method<span>:</span>
                        </span>
                        {orderObj?.paymentMethod === 'COD' ? 'COD' : 'Online'}
                      </li>
                      {/* <CustomButton isBtn iconName="fa-solid fa-check" btnName="Change Payment Mode" btntype="button" ClickEvent={() => alert("Invalid Payment Credentials Found")} /> */}
                      <li>
                        <span className="fw-600">
                          Amount<span>:</span>
                        </span>
                        ₹ {orderObj?.totalAmount}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          TXN ID<span>:</span>
                        </span>
                        none
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Date<span>:</span>
                        </span>
                        {moment(orderObj?.createdAt).format("DD-MM-YYYY")}
                      </li>
                      <li>
                        <span className="fw-600">
                          Payment Status<span>:</span>
                        </span>
                        {orderObj?.paymentObj?.paymentChk == 0 ? 'Pending' : 'Done'}
                      </li>

                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12">
                <DashboardBox className="main_text_color">
                  {
                    orderObj?.awbNo ? (
                      <div className="d-flex justify-content-between align-items-end">
                        <div>
                          <h5 className="main_text_color mb-3">AWB NO: {orderObj?.awbNo}</h5>
                          <CustomButton isBtn iconName="fa-solid fa-check" btnName="Track Order" btntype="button" ClickEvent={() => handleTrackOrder()} />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-end">
                        <div>
                          <h5 className="main_text_color mb-3"> Assign Order</h5>
                          <CustomButton isBtn iconName="fa-solid fa-check" btnName="Assign Order" btntype="button" ClickEvent={() => handleAssingtoOrder()} />
                        </div>
                      </div>
                    )
                  }

                  <DashboardTable className="my-4">
                    <h5 className="main_text_color mb-3">Product Details</h5>
                    <DataTable columns={sale_columns} data={orderObj?.productsArr} pagination />
                  </DashboardTable>
                  <div className="customer-profile">
                    <h6 className="main_text_color text-capitalize mb-3">Order Info</h6>
                    <ul className="main_text_color fs-14">
                      {/* <li>
                        <span className="fw-600">
                          Is Paid<span>:</span>
                        </span>
                        No
                      </li> */}


                      <li>
                        <span className="fw-600">
                          Subtotal<span>:</span>
                        </span>
                        ₹ {orderObj?.subTotalAmount}
                      </li>

                  
                      {
                        orderObj?.dicountObj && (
                          <li>
                            <span className="fw-600">
                              Discount ({orderObj?.dicountObj?.code})<span>:</span>
                            </span>
                            - ₹ {orderObj?.dicountObj?.amount}
                          </li>
                        )
                      }

                      <li>
                        <span className="fw-600">
                          Shipping Charge<span>:</span>
                        </span>
                        Free
                      </li>

                      {

                        orderObj?.giftwarp == true && (
                          <li>
                            <span className="fw-600">
                              GiftWrap<span>:</span>
                            </span>
                            + ₹ 99
                          </li>
                        )

                      }
                      {/* <li>
                        <span className="fw-600">
                          TAX/GST<span>:</span>
                        </span>
                        ₹ {orderObj?.totalPriceWithGst - orderObj?.totalAmount}
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Grand Total<span>:</span>
                        </span>
                        ₹ {orderObj?.totalAmount}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
            </DashboardBox>
            <div className="col-12 col-md-4">
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label>ORDER CONFIRMATION</label>
                    <Select options={MainOrderStatusArr} value={selectedOrderStatus} onChange={(e) => setSelectedOrderStatus(e)} />
                  </div>
                  {/* <div className="col-12">
                    <label>PAYMENT STATUS</label>
                    <Select options={Order_Payment_Status_Filter_Arr} value={selectedPaymentStatusStr} onChange={(e) => setSelectedPaymentStatusStr(e)} />
                  </div> */}

                  {/* <div className="col-12">
                    <label>DELIVERY STATUS</label>
                    <Select options={delivery} />
                  </div> */}
                  <div className="col-12 text-center mt-2">
                    <CustomButton isBtn iconName="fa-solid fa-check" btnName="Save" btntype="button" ClickEvent={() => handleOrderStatusUpdate()} />
                  </div>
                </form>
              </DashboardBox>
              <div className="col-12 col-md-12 mt-4">
                <DashboardBox>
                  <form className="form row">
                    <div className="col-12">
                      <label>Height</label>
                      <input className="form-control" placeholder="Height" value={Height} onChange={(e) => setHeight(e?.target?.value)} />
                    </div>
                    <div className="col-12">
                      <label>Width</label>
                      <input className="form-control" placeholder="Width" value={width} onChange={(e) => setWidth(e?.target?.value)} />
                    </div>
                    <div className="col-12">
                      <label>Length</label>
                      <input className="form-control" placeholder="Length" value={Length} onChange={(e) => setLength(e?.target?.value)} />
                    </div>
                    {/* <div className="col-12 text-center mt-2">
                      <CustomButton isBtn iconName="fa-solid fa-check" btnName="Assign" btntype="button" ClickEvent={() => handleAssingtoOrder()} />
                    </div> */}
                    <div className="col-12 text-center mt-2">
                      <CustomButton isBtn iconName="fa-solid fa-check" btnName="Save" btntype="button" ClickEvent={() => handleOrderDimensionsUpdate()} />
                    </div>
                  </form>
                </DashboardBox>
              </div>
            </div>
          </div>
        </div>

        <Modal open={isModel} onClose={() => setIsModel(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="modal-box">
            <div className="modal-container" >
              <div className="modal-header">
                <h5>Track Order</h5>
                <CustomButton
                  isBtn
                  btntype="button"
                  iconName="ion-close-circled text-white"
                  changeClass="border-0 bg-transparent rounded-circle modal-close"
                  ClickEvent={(e) => {
                    e.preventDefault();
                    setIsModel(false);
                  }}
                />
              </div>

              <div className="modal-body">

                <h5>Current Status: {trackObj?.trackingStatusObj?.current_status}</h5>
                <div className="row my-3">

                  <div className="col-4">  <h5>Status</h5></div>
                  <div className="col-4"><h5>Activity</h5></div>
                  <div className="col-4"><h5>Date</h5></div>
                </div>
                {
                  trackObj?.trackingStatusArr?.length > 0 && (
                    trackObj?.trackingStatusArr?.map((trea) => (
                      <div className="row my-3">

                        <div className="col-4">{trea["sr-status-label"]}</div>
                        <div className="col-4">{trea?.activity}</div>
                        <div className="col-4">{trea?.date}</div>
                      </div>
                    ))
                  )
                }

              </div>
            </div>
          </Box>
        </Modal>
      </section>
    </main>
  );
}

export default SaleDetail;
