import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as ARTIST from "../../actions/Artist/Artist.actions";

const initialState = {
  artists: null,
  artistObj: null,
  loading: false,
  error: null,
};

export const ArtistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTIST.ARTIST_ADD:
      return {
        ...state,
        loading: true,
      };
    case ARTIST.ARTIST_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case ARTIST.ARTIST_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ARTIST.GET_ALL_ARTISTS:
      return {
        ...state,
        loading: true,
      };
    case ARTIST.GET_ALL_ARTISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        artists: action.payload.data,
      };
    case ARTIST.GET_ALL_ARTISTS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ARTIST.DELETE_ARTIST_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case ARTIST.DELETE_ARTIST_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ARTIST.DELETE_ARTIST_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ARTIST.SET_ARTIST_OBJ:
      return {
        ...state,
        loading: true,
      };
    case ARTIST.SET_ARTIST_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        artistObj: action.payload.data,
        loading: false,
        error: null,
      };
    case ARTIST.SET_ARTIST_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
