import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/artist";

export const addArtist = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addArtist", formData);
};

export const getArtist = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getArtist?${query}`);
};

export const deleteArtist = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateArtist = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
