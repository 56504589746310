import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BANNERAdd,
  BANNERUpdate,
  SetBANNERObj,
} from "../../redux/actions/Banner/Banner.actions";
import { CATEGORYGet } from "../../redux/actions/Category/Category.actions";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import Select from "react-select";
import lodash from "lodash";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { async } from "@firebase/util";
import {
  addhomepageData,
  gethomepageData,
} from "../../services/homepage.service";
import FileUpload from "../Utility/FileUpload";
import { generateFilePath } from "../Utility/utils";
import { COLLECTIONGet } from "../../redux/actions/Collection/Collection.actions";
import { ARTISTGet } from "../../redux/actions/Artist/Artist.actions";
import { getAdminAllProducts } from "../../services/product.service";

function HomePage() {
  const dispatch = useDispatch();
  const categoryArrFromRedux = useSelector(
    (state) => state.category.categories
  );
  const collectionArrFromRedux = useSelector(
    (state) => state.collection.collections
  );
  const artistArrFromRedux = useSelector(
    (state) => state.artist.artists
  );
  const [name, setName] = useState("");

  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainCollectionArr, setMainCollectionArr] = useState([]);
  const [mainArtistArr, setMainArtistArr] = useState([]);
  const [displayProductArr, setDisplayProductArr] = useState([]);

  const [section1Heading, setSection1Heading] = useState("");
  const [section1BoldHeading, setSection1BoldHeading] = useState("");
  const [section1Description, setSection1Description] = useState("");

  const [backendData, setBackendData] = useState();

  const handleGetHomepage = async () => {
    try {
      let { data: res } = await gethomepageData('htype=home');
      if (res.data) {
        let sectioData = res.data;
        sectioData.htype = 'home';
        sectioData.section2.collectionArr = sectioData.section2.collectionArr.map(
          (el) => ({ ...el, value: el.collectionId })
        );
        sectioData.section3.categoryArr = sectioData.section3.categoryArr.map(
          (el) => ({ ...el, value: el.categoryId })
        );

        sectioData.section4.productArr = sectioData.section4.productArr.map(
          (el) => ({ ...el, value: el.productId })
        );

        sectioData.section5.artistArr = sectioData.section5.artistArr.map(
          (el) => ({ ...el, value: el.artistId })
        );

        
        setBackendData(sectioData);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetHomepage();
    dispatch(CATEGORYGet("level=1"));
    dispatch(COLLECTIONGet("level=1"));
    dispatch(ARTISTGet("level=1"));
    handleGetProducts()
  }, []);




  const handleGetProducts = async () => {
    try {
      let queryOf = `isPaginate=true`;


   
      // console.log(queryOf,"queryOfqueryOfqueryOfqueryOf")
      // dispatch(PRODUCTGet(queryOf));

      let { data: get } = await getAdminAllProducts(queryOf);
      console.log(get, "get =====");
        if (get.data) {
          setDisplayProductArr([
            ...get?.data.map((el) => {
              el.checked = false;
              return el;
            }),
          ]);
      }
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    if (categoryArrFromRedux) {
      setMainCategoryArr([...categoryArrFromRedux]);
    }
  }, [categoryArrFromRedux]);
  useEffect(() => {
    if (collectionArrFromRedux) {
      setMainCollectionArr([...collectionArrFromRedux]);
    }
  }, [collectionArrFromRedux]);
  useEffect(() => {
    if (artistArrFromRedux) {
      setMainArtistArr([...artistArrFromRedux]);
    }
  }, [artistArrFromRedux]);

  const handleSetValueOfBackendData = (value, key, key2, key3, key4) => {
    let backendDataTemp = backendData;
    console.log(
      value,
      key,
      key2,
      key3,
      key4,
      " backendDataTemp[key][key2] backendDataTemp[key][key2]"
    );
    if (key2 == "categoryArr") {
      let tempcatArr = value.map((el) => ({ ...el, categoryId: el.value }));
      if(tempcatArr?.length > 5){
         toastError("Max limit is five")
        return 
      }
      backendDataTemp[key][key2] = tempcatArr;
    } 
    else if (key2 == "collectionArr") {
      let tempcatArr = value.map((el) => ({ ...el, collectionId: el.value }));
      backendDataTemp[key][key2] = tempcatArr;
    }
    else if (key2 == "productArr") {
      let tempcatArr = value.map((el) => ({ ...el, productId: el.value }));
      backendDataTemp[key][key2] = tempcatArr;
    }
    else if (key2 == "artistArr") {
      let tempcatArr = value.map((el) => ({ ...el, artistId: el.value }));
      backendDataTemp[key][key2] = tempcatArr;
    }
    else if (key2 == "sectionArr") {
      let tempcatArr = [...backendDataTemp[key][key2]];

      console.log(tempcatArr, "tempcatArr1");
      tempcatArr[key3][key4] = value;
      console.log(tempcatArr, "tempcatArr");

      backendDataTemp[key][key2] = [...tempcatArr];
    } else {
      backendDataTemp[key][key2] = value;
    }

    setBackendData({ ...backendDataTemp });
  };

  const handleSubmit = async () => {
    try {
      let obj = backendData;
      let { data: res } = await addhomepageData(obj);
      if (res.success) {
        toastSuccess(res.message);
      }
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="main_text_color mb-4">Hompage</h5>
          <form className="form">
            <div className="row my-4">
              <div className="col-12 col-md-12 mb-0">
                <DashboardBox>
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 1</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section1?.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section1",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Description <span className="red">*</span>
                      </label>
                      <textarea
                        rows={3}
                        value={backendData?.section1?.description}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section1",
                            "description"
                          )
                        }
                        type="text"
                        className="form-control"
                      ></textarea>
                    </div>
                  
                  
                  </div>
                  <hr />
                 <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 2</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section2.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section2",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Collection to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={backendData?.section2.collectionArr}
                          isMulti
                          onChange={(event) =>
                            handleSetValueOfBackendData(
                              event,
                              "section2",
                              "collectionArr",
                              "multi",
                              "collectionId"
                            )
                          }
                          options={
                            mainCollectionArr &&
                            mainCollectionArr.length > 0 &&
                            mainCollectionArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div> 
                  <hr />
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 3</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section3.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section3",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Categories to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={backendData?.section3.categoryArr}
                          isMulti
                          onChange={(event) =>
                            handleSetValueOfBackendData(
                              event,
                              "section3",
                              "categoryArr",
                              "multi",
                              "categoryId"
                            )
                          }
                          options={
                            mainCategoryArr &&
                            mainCategoryArr.length > 0 &&
                            mainCategoryArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div> 
                  <hr />
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 4</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section4.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section4",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                 
                    <div className="col-12">
                      <label>
                        Products to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={backendData?.section4.productArr}
                          isMulti
                          onChange={(event) =>
                            handleSetValueOfBackendData(
                              event,
                              "section4",
                              "productArr",
                              "multi",
                              "categoryId"
                            )
                          }
                          options={
                            displayProductArr &&
                            displayProductArr.length > 0 &&
                            displayProductArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div>
               
                  </div>
                  <hr />
               
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 5</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section5.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section5",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Artists to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={backendData?.section5.artistArr}
                          isMulti
                          onChange={(event) =>
                            handleSetValueOfBackendData(
                              event,
                              "section5",
                              "artistArr",
                              "multi",
                              "artistId"
                            )
                          }
                          options={
                            mainArtistArr &&
                            mainArtistArr.length > 0 &&
                            mainArtistArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div> 
                  {/* <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 5</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section5.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section5",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    {backendData.section5.sectionArr &&
                      backendData.section5.sectionArr.length > 0 &&
                      backendData.section5.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-4">
                            <label>
                              Name <span className="red">*</span>
                            </label>
                            <input
                              value={sect.name}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section5",
                                  "sectionArr",
                                  seINdex,
                                  "name"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section5",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200} alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)} width={200} alt="No  Found"
                                  height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section5",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 6</h5>

                    {backendData.section6.sectionArr &&
                      backendData.section6.sectionArr.length > 0 &&
                      backendData.section6.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-6">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section6",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-6">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200} alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)} width={200} alt="No  Found"
                                  height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section6",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />
                
                  <hr />
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 8</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section8.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section8",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    {backendData.section8.sectionArr &&
                      backendData.section8.sectionArr.length > 0 &&
                      backendData.section8.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-4">
                            <label>
                              Name <span className="red">*</span>
                            </label>
                            <input
                              value={sect.name}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section8",
                                  "sectionArr",
                                  seINdex,
                                  "name"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section8",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200} alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)} width={200} alt="No  Found"
                                  height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section8",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 9</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section9.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section9",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    {backendData.section9.sectionArr &&
                      backendData.section9.sectionArr.length > 0 &&
                      backendData.section9.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-4">
                            <label>
                              Name <span className="red">*</span>
                            </label>
                            <input
                              value={sect.name}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section9",
                                  "sectionArr",
                                  seINdex,
                                  "name"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section9",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200} alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)} width={200} alt="No  Found"
                                  height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section9",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />

                  <hr />
             
                  <div className="row my-4">
                    <h5 className="main_text_color mb-4">Section 10</h5>

                    {backendData.section10.sectionArr &&
                      backendData.section10.sectionArr.length > 0 &&
                      backendData.section10.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-6">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section10",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-6">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200} alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)} width={200} alt="No  Found"
                                  height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section10",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div> */}
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      ClickEvent={handleSubmit}
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </DashboardBox>
              </div>

            
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default HomePage;
