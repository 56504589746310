import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { ARTISTGet, SetARTISTObj } from "../../../redux/actions/Artist/Artist.actions";
import { generateFilePath } from "../../Utility/utils";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import { async } from "@firebase/util";
import { deleteArtist } from "../../../services/artist.service";

function Artists() {
  const dispatch = useDispatch();

  const artistArr = useSelector((state) => state.artist.artists);
  const [artistesArr, setArtistesArr] = useState([]);
  const [displayAristArr, setDisplayArtistArr] = useState([]);
  const [query, setQuery] = useState("");






  useEffect(() => {
    if (artistArr?.length) {
      setArtistesArr(artistArr);
      setDisplayArtistArr(artistArr);
    }
  }, [artistArr]);


  useEffect(() => {
    dispatch(ARTISTGet());
  }, []);
  const handleEdit = (row) => {
    dispatch(SetARTISTObj(row));
  };
  const handelDelete = async (id) => {
    try {
      let { data: res } = await deleteArtist(id)
      if (res.success) {
        toastSuccess(res.message)
        dispatch(ARTISTGet());
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "20%",
    },
    {
      name: "Image",
      grow: 0,
      width: "20%",
      cell: (row) => <img height="84px" width="56px" alt={row.name} src={generateFilePath(row.image)} />,
    },
    {
      name: "Status",
      button: true,
      width: "20%",
      cell: (row) => (<> {row.status == true ?<CustomButton greenBtn btnName="Active"  /> : <CustomButton redBtn btnName="InActive"  /> }</>),
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Artists/Artist-Create" onDeleteClick={() => handelDelete(row._id)} deletePath="/Artists" remove edit Uniquekey={row.id} />,
    },
  ];

  const handleFilterByQuery = (e, requiredParametersArr) => {
    let tempArr = displayAristArr.filter((el) => {
      for (const ele of requiredParametersArr) {
        console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el");
        if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
          // console.log("true")
          return true;
        } else {
          return false;
        }
      }
    });
    setQuery(e);
    setArtistesArr([...tempArr]);
    console.log([...tempArr], "...tempArr");
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Artist List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW ARTIST" path="/Artists/Artist-Create" small roundedPill />
                  <SearchBox
                    handleChange={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={artistesArr && artistesArr.length > 0 ? artistesArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Artists;
