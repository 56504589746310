import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { ARTISTAdd, ARTISTUpdate, SetARTISTObj } from "../../../redux/actions/Artist/Artist.actions";
import { toastError } from "../../Utility/ToastUtils";

function AddArtist() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [isUpdateArtist, setIsUpdateArtist] = useState(false);
  const [selectedArtistId, setselectedArtistId] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [post, setpost] = useState("");
  const [status, setStatus] = useState(true);
  const [isMobile, setisMobile] = useState(false);
  const artistObj = useSelector((state) => state.artist.artistObj);

  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };

  useEffect(() => {
    if (artistObj) {
      setselectedArtistId(artistObj._id);
      setName(artistObj.name);
      setDescription(artistObj.description);
      setUrl(artistObj.url);
      setselectedArtistId(artistObj._id);
      setImageStr(artistObj.image);
      setBannerImage(artistObj.bannerImage);
      setisMobile(artistObj.isMobile);
      setpost(artistObj.post);
      // setSelectedStatus({ value: brandObj.statusInfo, label: brandObj.statusInfo });
      setIsUpdateArtist(true);
    }
    return () => {
      dispatch(SetARTISTObj(null));
    };
  }, [artistObj]);

  const handleSubmit = () => {

    try {
      if(name == ""){
        toastError("Please Enter Name of Artist");
        return 0
      }
  
      if(imageStr == ""){
        toastError("Please Upload Artist image");
        return 0
      }
      let obj = {
        name,
        description,
        status,
        url,
        bannerImage,
        isMobile,
        image: imageStr ,
        post
      };
      if (isUpdateArtist) {
        dispatch(ARTISTUpdate(obj, selectedArtistId));
      } else {
        dispatch(ARTISTAdd(obj));
      }
    } catch (error) {
        toastError(error)
    }

 
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">{isUpdateArtist ? "Update" : "Add New "} Artist</h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Artist Information</h5>
                    <div className="col-12">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>Caption</label>
                      <input value={post} onChange={(event) => setpost(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>Description</label>
                      <textarea value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" ></textarea>
                    </div>
                    {/* <div className="col-12">
                      <label>WEBSITE LINK</label>
                      <input value={url} onChange={(event) => setUrl(event.target.value)} type="text" className="form-control" />
                    </div> */}

                   
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label>Artist (150x150)PX</label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                    <div className="col-12 my-4">
                      <label>Banner Image</label>
                      <FileUpload onFileChange={(val)=>setBannerImage(val)} />
                    </div>
                    {/* <div className="col-6">
                      <label>Is Mobile</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={isMobile}
                          onChange={() => setisMobile(!isMobile)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-artist"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-artist"
                        >
                          Active
                        </label>
                      </div>
                      </div> */}
                    <div className="col-6">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={status}
                          onChange={() => setStatus(!status)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-artist"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-artist"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddArtist;
