import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { BANNERAdd, BANNERUpdate, SetBANNERObj } from "../../../redux/actions/Banner/Banner.actions";
import { toastError } from "../../Utility/ToastUtils";
import Select from "react-select";
import { CATEGORYGet } from "../../../redux/actions/Category/Category.actions";

function AddBanner() {
  const dispatch = useDispatch();
  const categoryArrFromRedux = useSelector(
    (state) => state.category.categories
  );
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [isUpdateBanner, setIsUpdateBanner] = useState(false);
  const [selectedBannerId, setselectedBannerId] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [youtube, setyoutube] = useState("");
  const [status, setStatus] = useState(true);
  const [isMobile, setisMobile] = useState(false);
  const [categoryObj, setCategoryObj] = useState({label:"",value:""});
  const bannerObj = useSelector((state) => state.banner.bannerObj);
  const [mainCategoryArr, setMainCategoryArr] = useState([]);

  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };


  useEffect(() => {
    dispatch(CATEGORYGet("level=1"));
  }, []);


  useEffect(() => {
    if (bannerObj) {
      setselectedBannerId(bannerObj._id);
      setName(bannerObj.name);
      setDescription(bannerObj.description);
      setUrl(bannerObj.url);
      setselectedBannerId(bannerObj._id);
      setImageStr(bannerObj.image);
      setisMobile(bannerObj.isMobile);
      setyoutube(bannerObj.youtube);
      // setSelectedStatus({ value: brandObj.statusInfo, label: brandObj.statusInfo });
      setIsUpdateBanner(true);
    }
    return () => {
      dispatch(SetBANNERObj(null));
    };
  }, [bannerObj]);


  useEffect(() => {
    if (categoryArrFromRedux) {
      setMainCategoryArr([...categoryArrFromRedux]);
    }
  }, [categoryArrFromRedux]);
  const handleSubmit = () => {

    if (name == "") {
      toastError("Please Enter Name of Banner");
      return 0
    }

    if (imageStr == "") {
      toastError("Please Upload Banner image");
      return 0
    }
    let obj = {
      name,
      description,
      status,
      url,
      isMobile,
      image: imageStr ,
      youtube,
      categoryId:categoryObj?.value
    };
    if (isUpdateBanner) {
      dispatch(BANNERUpdate(obj, selectedBannerId));
    } else {
      dispatch(BANNERAdd(obj));
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="main_text_color mb-4">{isUpdateBanner ? "Update" : "Add New "} Banner</h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="main_text_color mb-4">Banner Information</h5>
                    <div className="col-12">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>
                    {/* <div className="col-12">
                      <label>
                        Categories to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={categoryObj}
                          isMulti
                          onChange={(val) =>
                            setCategoryObj(
                              val
                             
                            )
                          }
                          options={
                            mainCategoryArr &&
                            mainCategoryArr.length > 0 &&
                            mainCategoryArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div> */}
                    <div className="col-12">
                      <label>WEBSITE LINK</label>
                      <input value={url} onChange={(event) => setUrl(event.target.value)} type="text" className="form-control" />
                    </div>

                      {/* <div className="col-12">
                        <label>Youtube</label>
                        <input value={youtube} onChange={(event) => setyoutube(event.target.value)} type="text" className="form-control" />
                      </div> */}
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label>Banner (150x150)PX</label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                    <div className="col-6">
                      <label>Is Mobile</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={isMobile}
                          onChange={() => setisMobile(!isMobile)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-banner"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-banner"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={status}
                          onChange={() => setStatus(!status)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-banner"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-banner"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>

            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddBanner;
