export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
};


export const DISCOUNT_TYPE = {
  NONE: "NONE",
  FLATOFF: "FLATOFF",
  PERCENT: "PERCENT",
  
}

export const FLASH_SALE_CONSTANT = {
  FESTIVE_FLARE: "FESTIVE_FLARE",
  PARTY_PERFECTION: "PARTY_PERFECTION",
  DAY_SHINE: "DAY_SHINE",
  GLAMOUR: "GLAMOUR",
};
