import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { BrandReducer } from "./Brand/Brand.reducer";
import { BannerReducer } from "./Banner/Branner.reducer";
import { userReducer } from "./Users/users.reducer";
import { AttributeReducer } from "./Attribute/Attribute.reducer";
import { CategoryReducer } from "./Category/Category.reducer";
import { ProductReducer } from "./Product/Product.reducer";
import { couponReducer } from "./Coupon/Coupon.reducer"
import { ContactReducer } from "./Contact/Contact.reducer";
import { blogReducer } from "./Blog/Blog.reducer";
import { seoReducer } from "./Seo/Seo.reducer";
import { ArtistReducer } from "./Artist/Artist.reducer";
import { CollectionReducer } from "./Collection/Collection.reducer";
import { LocationReducer } from "./Location/Location.reducer";
import { GalleryReducer } from "./Gallery/Gallery.reducer";

const RootReducer = combineReducers({
  auth: authReducer,
  brand: BrandReducer,
  artist: ArtistReducer,
  banner: BannerReducer,
  contact: ContactReducer,
  users: userReducer,
  attribute: AttributeReducer,
  category: CategoryReducer,
  product: ProductReducer,
  coupon: couponReducer,
  blog:blogReducer,
  seo:seoReducer,
  collection:CollectionReducer,
  location:LocationReducer,
  gallery:GalleryReducer,

});

export default RootReducer;
