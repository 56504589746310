import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { COLLECTIONAdd, COLLECTIONUpdate, SetCOLLECTIONObj } from "../../../redux/actions/Collection/Collection.actions";
import { toastError } from "../../Utility/ToastUtils";

function AddCollection() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [isUpdateCollection, setIsUpdateCollection] = useState(false);
  const [selectedCollectionId, setselectedCollectionId] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [post, setpost] = useState("");
  const [status, setStatus] = useState(true);
  const [isMobile, setisMobile] = useState(false);
  const collectionObj = useSelector((state) => state.collection.collectionObj);

  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };

  useEffect(() => {
    if (collectionObj) {
      setselectedCollectionId(collectionObj._id);
      setName(collectionObj.name);
      setDescription(collectionObj.description);
      setUrl(collectionObj.url);
      setselectedCollectionId(collectionObj._id);
      setImageStr(collectionObj.image);
      setisMobile(collectionObj.isMobile);
      setpost(collectionObj.post);
      // setSelectedStatus({ value: brandObj.statusInfo, label: brandObj.statusInfo });
      setIsUpdateCollection(true);
    }
    return () => {
      dispatch(SetCOLLECTIONObj(null));
    };
  }, [collectionObj]);

  const handleSubmit = () => {

    if(name == ""){
      toastError("Please Enter Name of Collection");
      return 0
    }

    if(imageStr == ""){
      toastError("Please Upload Collection image");
      return 0
    }
    let obj = {
      name,
      description,
      status,
      url,
      isMobile,
      image: imageStr ,
      post
    };
    if (isUpdateCollection) {
      dispatch(COLLECTIONUpdate(obj, selectedCollectionId));
    } else {
      dispatch(COLLECTIONAdd(obj));
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">{isUpdateCollection ? "Update" : "Add New "} Collection</h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Collection Information</h5>
                    <div className="col-12">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>Caption</label>
                      <input value={post} onChange={(event) => setpost(event.target.value)} type="text" className="form-control" />
                    </div>
                    {/* <div className="col-12">
                      <label>Description</label>
                      <textarea value={description} onChange={(event) => setDescription(event)} className="form-control" ></textarea>
                    </div> */}
                    {/* <div className="col-12">
                      <label>WEBSITE LINK</label>
                      <input value={url} onChange={(event) => setUrl(event.target.value)} type="text" className="form-control" />
                    </div> */}

                   
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label>Collection (150x150)PX</label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                    {/* <div className="col-6">
                      <label>Is Mobile</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={isMobile}
                          onChange={() => setisMobile(!isMobile)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-collection"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-collection"
                        >
                          Active
                        </label>
                      </div>
                      </div> */}
                    <div className="col-6">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={status}
                          onChange={() => setStatus(!status)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-collection"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-collection"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddCollection;
