import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { GALLERYGet, SetGALLERYObj } from "../../../redux/actions/Gallery/Gallery.actions";
import { generateFilePath } from "../../Utility/utils";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import { async } from "@firebase/util";
import { deleteGallery } from "../../../services/gallery.service";

function Gallerys() {
  const dispatch = useDispatch();

  const galleryArr = useSelector((state) => state.gallery.gallerys);

  useEffect(() => {
    dispatch(GALLERYGet());
  }, []);

  const handleEdit = (row) => {
    dispatch(SetGALLERYObj(row));
  };
  const handelDelete = async (id) => {
    try {
      let { data: res } = await deleteGallery(id)
      if (res.success) {
        toastSuccess(res.message)
        dispatch(GALLERYGet());
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "20%",
    },
    {
      name: "Image",
      grow: 0,
      width: "20%",
      cell: (row) => <img height="84px" width="56px" alt={row.name} src={generateFilePath(row.image)} />,
    },
 
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Gallery/Gallery-Create" onDeleteClick={() => handelDelete(row._id)} deletePath="/Gallerys" remove edit Uniquekey={row.id} />,
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="main_text_color m-0">Gallery List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW GALLERY" path="/Gallery/Gallery-Create" small roundedPill />
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={galleryArr && galleryArr.length > 0 ? galleryArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Gallerys;
