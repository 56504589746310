// homepage
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/homepage";

export const addhomepageData = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};
export const updateFilterData = (formData) => {
    return axiosApiInstance.post(url + "/filter/updateFilter", formData);
};


export const addFilterData = (formData) => {
    return axiosApiInstance.post(url + "/filter", formData);
};

export const gethomepageData = (query) => {
    return axiosApiInstance.get(`${serverUrl}?${query}`);
};

export const getFilterData = () => {
    return axiosApiInstance.get(`${url}/filter`);
};

export const deleteFilter = (id) => {
    return axiosApiInstance.delete(`${url}/filter/deleteById/${id}`);
};

export const deleteOPtionFilter = (id,formData) => {
    return axiosApiInstance.patch(`${url}/filter/deleteOptionArr/${id}`,formData);
};
export const affFilterOptionData = (id,formData) => {
    return axiosApiInstance.post(`${url}/filter/addoptiondata/${id}`,formData);
};

