import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getProductStockBySellerId } from "../../redux/actions/ProductStock/ProductStock.actions";
import { usersGet } from "../../redux/actions/Users/users.actions";
import { updateStockManually } from "../../services/productsStocks.services";
import { toastError } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { addRupeeSymbol } from "../Utility/utils";
import Select from "react-select";

export default function SellerStocks() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  const stockArr = useSelector((state) => state.ProductStock.stocks);
  const [stocksArr, setStocksArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const [selectedSellerObj, setSelectedSellerObj] = useState(null);
  const [displaySellersArr, setDisplaySellersArr] = useState([]);

  const handleGet = () => {
    dispatch(getProductStockBySellerId(authObj?.user?._id));
  };

  useEffect(() => {
    handleGet();
  }, []);
  const getSellerUsers = () => {
    dispatch(usersGet("role=SELLER"));
  };

  useEffect(() => {
    getSellerUsers();
  }, []);
  useEffect(() => {
    if (stockArr) {
      let tempArr = [...stockArr.map((el) => ({ ...el, updateStockEl: 0 }))];
      setStocksArr(tempArr);
      setMainArr(tempArr);
    }
  }, [stockArr]);

  useEffect(() => {
    if (userArr && userArr.length > 0) {
      setDisplaySellersArr([
        ...userArr.map((el) => {
          el.label = el?.name;
          el.value = el?._id;
          return el;
        }),
      ]);
    }
  }, [userArr]);

  const handleSellerFilter = (obj) => {
    setSelectedSellerObj(obj);
    let tempArr = [...mainArr];
    tempArr = tempArr.filter((el) => el.sellerId == obj._id);
    setStocksArr([...tempArr]);
    // if (filterStatus && filterStatus?.value != "") {
    //   dispatch(PRODUCTGet(`sellerId=${obj.value}&status=${filterStatus?.value}`));
    // } else {
    //   dispatch(PRODUCTGet(`sellerId=${obj.value}`));
    // }
  };

  const handleStockUpdate = async (obj) => {
    try {
      let tempobj = {
        productId: obj.productId,
        variantId: obj.variantId,
        sellerId: obj.sellerId,
        updateStockEl: obj.updateStockEl,
      };
      const { data: res } = await updateStockManually(tempobj);
      if (res) {
        dispatch(getProductStockBySellerId(authObj?.user?._id));
      }
    } catch (error) {
      toastError(error);
    }
  };

  console.log(stockArr);

  const handleUpdateStockChange = (event, index) => {
    let tempArr = [...stocksArr];
    tempArr[index].updateStockEl = event.target.value;
    setStocksArr([...tempArr]);
  };

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      cell: (row) => row?.name,

      sortable: true,
    },
    {
      name: "Attribute",
      selector: (row) => row?.attributeName,
      cell: (row) => row?.attributeName,

      sortable: true,
    },
    {
      name: "Sku",
      selector: (row) => row?.sku,
      sortable: true,
    },
    {
      name: "Product Code",
      selector: (row) => row?.productCode,
      sortable: true,
    },
    {
      name: "Stock",
      selector: (row) => row?.attributeStock,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.attributePrice,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row, index) => (
    //     <>
    //       <input className="form-control" placeholder="Update Stock" value={row?.updateStockEl} onChange={(e) => handleUpdateStockChange(e, index)} />
    //       <ActionIcon Uniquekey={row._id} edit isRedirected={true} onEditClick={() => handleStockUpdate(row)} editPath="/my-stocks" />
    //     </>
    //   ),
    //   sortable: false,
    // },
  ];

  // ==============================================================================================================

  const handleChange = (e, requiredParametersArr) => {
    setQuery(e);

    let tempArr = mainArr.filter((el) => {
      for (const ele of requiredParametersArr) {
        if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      }
    });
    console.log(tempArr);
    setStocksArr([...tempArr]);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="main_text_color m-0">My Stocks</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Stock CSV" path="/BulkStocksUpload" small roundedPill downloadAble />
                  <div className="m-2">
                    <Select placeholder="Vendor" options={displaySellersArr} value={selectedSellerObj} onChange={(e) => handleSellerFilter(e)} />
                  </div> */}
                  <div className="search-field">
                    <form className="form">
                      <div className={ "input-group"}>
                        <div className="input-group-text">
                          <i className="ion-ios-search-strong blue-1"></i>
                        </div>
                        <input value={query} type="text" onChange={(e) =>  handleChange(e.target.value, ["name", "attributeName","productCode","sku"])} className="form-control" placeholder="Search" />
                      </div>
                    </form>
                  </div>
                  {/* <SearchBox
                    setQuery={(e) => {
                      handleChange(e, ["name", "attributeName","productCode","sku"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={category_columns} data={stocksArr && stocksArr.length > 0 ? stocksArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
