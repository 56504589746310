import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { COLLECTIONGet, SetCOLLECTIONObj } from "../../../redux/actions/Collection/Collection.actions";
import { generateFilePath } from "../../Utility/utils";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import { async } from "@firebase/util";
import { deleteCollection } from "../../../services/collection.service";

function Collection() {
  const dispatch = useDispatch();

  const collectionArr = useSelector((state) => state.collection.collections);
  
  const [collectionesArr, setCollectionesArr] = useState([]);
  const [displayCollectionArr, setDisplayCollectionArr] = useState([]);
  const [query, setQuery] = useState("");






  useEffect(() => {
    if (collectionArr?.length) {
      setCollectionesArr(collectionArr);
      setDisplayCollectionArr(collectionArr);
    }
  }, [collectionArr]);



  
  const handleFilterByQuery = (e, requiredParametersArr) => {
    let tempArr = displayCollectionArr.filter((el) => {
      for (const ele of requiredParametersArr) {
        console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el");
        if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
          // console.log("true")
          return true;
        } else {
          return false;
        }
      }
    });
    setQuery(e);
    setCollectionesArr([...tempArr]);
    console.log([...tempArr], "...tempArr");
  }
  useEffect(() => {
    dispatch(COLLECTIONGet());
  }, []);

  const handleEdit = (row) => {
    dispatch(SetCOLLECTIONObj(row));
  };
  const handelDelete = async (id) => {
    try {
      let { data: res } = await deleteCollection(id)
      if (res.success) {
        toastSuccess(res.message)
        dispatch(COLLECTIONGet());
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "20%",
    },
    {
      name: "Image",
      grow: 0,
      width: "20%",
      cell: (row) => <img height="84px" width="56px" alt={row.name} src={generateFilePath(row.image)} />,
    },
    {
      name: "Status",
      button: true,
      width: "20%",
      cell: (row) => (<> {row.status == true ?<CustomButton greenBtn btnName="Active"  /> : <CustomButton redBtn btnName="InActive"  /> }</>),
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Collections/Collection-Create" onDeleteClick={() => handelDelete(row._id)} deletePath="/Collections" remove edit Uniquekey={row.id} />,
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Collection List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW COLLECTION" path="/Collections/Collection-Create" small roundedPill />
                  <SearchBox
                    handleChange={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={collectionesArr && collectionesArr.length > 0 ? collectionesArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Collection;
