import { Route, Routes } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import AddBlog from "../components/Blog/AddBlog";
import Blog from "../components/Blog/Blog";
import BlogCategory from "../components/Blog/BlogCategory";
import AddPage from "../components/Page/AddPage";
import Page from "../components/Page/Page";
import ShowBlog from "../components/Blog/ShowBlog";
import ContactMail from "../components/ContactMail/ContactMail";
import ShowContact from "../components/ContactMail/ShowContact";
import Dashboard from "../components/dashboard/Dashboard";
import HomepageCMS from "../components/Frontend-CMS/Homepage";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SaleDetail from "../components/Order/Detail/SaleDetail";
import TotalOrder from "../components/Order/TotalOrder/TotalOrder";

import AddProduct from "../components/Products/AddProduct/AddProduct";
import UpdateProductListPage from "../components/Products/AddProduct/UpdateProductListPage";
import Attribute from "../components/Products/Attribute/Attribute";
import AttributeValue from "../components/Products/Attribute/AttributeValue";
import Brand from "../components/Products/Brand/Brand";
import Category from "../components/Products/Category/Category";
import ProductList from "../components/Products/ProductList";
import ProductReview from "../components/Review/ProductReview";

import SideBar from "../components/Sidebar/SideBar";
import SellerStocks from "../components/Stocks/SellerStocks";
import AddUser from "../components/Users/AddUser";
import EditUser from "../components/Users/EditUser";
import UserDetail from "../components/Users/UserDetail";
import User from "../components/Users/Users";
import Coupon from "../components/Coupon/Coupon";
import AddReview from "../components/Review/AddReview";
import Subscriber from "../components/Subscriber/Subscriber";
import Setting from "../components/SystemSetting/Setting";
import Contact from "../components/Frontend-CMS/Contacts/Contact";
import ProductEnquiry from "../components/Frontend-CMS/Contacts/ProductEnquiry";
import ViewSeo from "../components/Seo/ViewSeo";
import AddSeo from "../components/Seo/AddSeo";
import Artists from "../components/Products/Artist/Artist.jsx";
import AddArtist from "../components/Products/Artist/AddArtist.jsx";
import AddCollection from "../components/Products/Collection/AddCollection.jsx";
import Collection from "../components/Products/Collection/Collection.jsx";
import AddLocation from "../components/Products/Location/AddLocation.jsx";
import Location from "../components/Products/Location/Location.jsx";
import Banners from "../components/Frontend-CMS/Banners/Banners.jsx";
import AddBanner from "../components/Frontend-CMS/Banners/AddBanner.jsx";
import AddGallery from "../components/Frontend-CMS/Gallery/AddGallery.jsx";
import Gallerys from "../components/Frontend-CMS/Gallery/Gallery.jsx";
import Faq from "../components/Frontend-CMS/FAQ/Faq.jsx";
import AddFaq from "../components/Frontend-CMS/FAQ/AddFaq.jsx";
import PrivacyPolicy from "../components/Page/PrivacyPolicy.jsx";
import RefundPolicy from "../components/Page/RefundPolicy.jsx";
import ReturnPolicy from "../components/Page/ReturnPolicy.jsx";
import TermsConditions from "../components/Page/TermsConditions.jsx";
import ShippingPolicy from "../components/Page/ShippingPolicy.jsx";

export default function AuthorizedRoutes() {
  return (
    <section className="body_bg">
      <div className="row g-0">
        <div
          className="col-12 col-md-2 displayNone"
          style={{ contain: "content" }}
        >
          <SideBar />
        </div>
        <div
          className="col-12 col-md-10 displayNoneMain"
          style={{ height: "100vh", overflow: "hidden scroll" }}
        >
          <Header />
          <Routes>
            <Route exact path="/Dashboard" element={<Dashboard />}></Route>
            <Route
              exact
              path="/Product/Category"
              element={<Category />}
            ></Route>
            <Route exact path="/Product/Brand" element={<Brand />}></Route>
            <Route exact path="/Artist/Artist" element={<Artists />}></Route>
            <Route
              exact
              path="/Artists/Artist-Create"
              element={<AddArtist />}
            ></Route>
            <Route
              exact
              path="/Collection/Collection"
              element={<Collection />}
            ></Route>
            <Route
              exact
              path="/Collections/Collection-Create"
              element={<AddCollection />}
            ></Route>
            <Route
              exact
              path="/Location/Location"
              element={<Location />}
            ></Route>
            <Route
              exact
              path="/Locations/Location-Create"
              element={<AddLocation />}
            ></Route>
            <Route
              exact
              path="/Product/Update-Product"
              element={<UpdateProductListPage />}
            ></Route>
            <Route
              exact
              path="/Product/Attribute"
              element={<Attribute />}
            ></Route>
            <Route
              exact
              path="/Product/Attribute-Value"
              element={<AttributeValue />}
            ></Route>
            <Route
              exact
              path="/Product/AddProduct"
              element={<AddProduct />}
            ></Route>
            <Route
              exact
              path="/Product/Product-List"
              element={<ProductList />}
            ></Route>
            <Route exact path="/Blog/post" element={<Blog />}></Route>
            <Route exact path="/Blog/post/create" element={<AddBlog />}></Route>
            <Route exact path="/Page/page" element={<Page />}></Route>
            <Route exact path="/Page/page/create" element={<AddPage />}></Route>
            <Route
              exact
              path="/Blog/Category"
              element={<BlogCategory />}
            ></Route>
            <Route exact path="/Blog/View-Post" element={<ShowBlog />}></Route>
            <Route
              exact
              path="/Order/Total-Order"
              element={<TotalOrder />}
            ></Route>
            <Route
              exact
              path="/Order/Sale-Detail"
              element={<SaleDetail />}
            ></Route>
            <Route exact path="/Contact-Mail" element={<ContactMail />}></Route>
            <Route exact path="/Contact-Info" element={<ShowContact />}></Route>
            <Route
              exact
              path="/Review/Product-Review"
              element={<ProductReview />}
            ></Route>
            <Route
              exact
              path="/Review/Product-Review/Create-Review"
              element={<AddReview />}
            ></Route>
            <Route exact path="/Coupon/Coupon" element={<Coupon />}></Route>
            <Route exact path="/User-list" element={<User />}></Route>
            <Route
              exact
              path="/User/User-edit/:id"
              element={<EditUser />}
            ></Route>
            <Route exact path="/User-Create" element={<AddUser />}></Route>
            <Route exact path="/User-Detail" element={<UserDetail />}></Route>
            <Route exact path="/my-stocks" element={<SellerStocks />}></Route>
            <Route exact path="/HomepageCMS" element={<HomepageCMS />}></Route>
            <Route
              exact
              path="/System-Setting/Setting"
              element={<Setting />}
            ></Route>
            <Route exact path="/Subscriber" element={<Subscriber />}></Route>
            <Route exact path="/Contact" element={<Contact />}></Route>
            <Route
              exact
              path="/ProductEnquiry"
              element={<ProductEnquiry />}
            ></Route>
            <Route exact path="/Seo/View-Seo" element={<ViewSeo />}></Route>
            <Route exact path="/Seo/Add-Seo" element={<AddSeo />}></Route>
            <Route exact path="/Banners" element={<Banners />}></Route>
            <Route
              exact
              path="/Banners/Banner-Create"
              element={<AddBanner />}
            ></Route>
            <Route exact path="/Gallery" element={<Gallerys />}></Route>
            <Route
              exact
              path="/Gallery/Gallery-Create"
              element={<AddGallery />}
            ></Route>
            <Route exact path="/Faq" element={<Faq />}></Route>
            <Route exact path="/Faq/Faq-Create" element={<AddFaq />}></Route>
            <Route
              exact
              path="/Privacy-Policy"
              element={<PrivacyPolicy />}
            ></Route>
            <Route
              exact
              path="/Refund-Policy"
              element={<RefundPolicy />}
            ></Route>
            <Route
              exact
              path="/Return-Policy"
              element={<ReturnPolicy />}
            ></Route>
            <Route
              exact
              path="/Shipping-Policy"
              element={<ShippingPolicy />}
            ></Route>
            <Route
              exact
              path="/Terms-Conditions"
              element={<TermsConditions />}
            ></Route>
          </Routes>
          <Footer />
        </div>
      </div>
    </section>
  );
}
