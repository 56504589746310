import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { BANNERGet, SetBANNERObj } from "../../../redux/actions/Banner/Banner.actions";
import { generateFilePath } from "../../Utility/utils";
import { deleteFaqById, getFaq } from "../../../services/Faq.service";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";

function Faq() {
  const dispatch = useDispatch();
  const [faqArr, setFaqArr] = useState([]);
  const [faqesArr, setFaqesArr] = useState([]);
  const [displayFaqArr, setDisplayFaqArr] = useState([]);
  const [query, setQuery] = useState("");

  
  useEffect(() => {
    if (faqArr?.length) {
      setFaqesArr(faqArr);
      setDisplayFaqArr(faqArr);
    }
  }, [faqArr]);

  const handleFilterByQuery = (e, requiredParametersArr) => {
    let tempArr = displayFaqArr.filter((el) => {
      for (const ele of requiredParametersArr) {
        console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el");
        if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
          // console.log("true")
          return true;
        } else {
          return false;
        }
      }
    });
    setQuery(e);
    setFaqesArr([...tempArr]);
    console.log([...tempArr], "...tempArr");
  }

  const handleGet = async (e) => {
    try {
      let { data: res } = await getFaq()
      if (res.data) {
        setFaqArr(res.data)
        // toastSuccess(res.message)
      }
      // if (isUpdateFaq) {
      // dispatch(BANNERUpdate(obj, selectedFaqId));
      // } else {
      // dispatch(BANNERAdd(obj));
      // }
    }
    catch (err) {
      toastError(err)
    }
  };

  useEffect(() => {
    handleGet()
  }, []);
  const handleEdit = (row) => {
    dispatch(SetBANNERObj(row));
  };


  const handelDelete = async (id) => {
    try {
      let { data: res } = await deleteFaqById(id)
      if (res.success) {
        toastSuccess(res.message)
        handleGet()
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "20%",
    },
    {
      name: "Heading",
      selector: (row) => row.heading,
      width: "20%",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "20%",
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Faq/Faq-Create" onDeleteClick={() => handelDelete(row?._id)} deletePath="/Faq" remove edit  Uniquekey={row._id} />,
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="main_text_color m-0">Faq List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW FAQ" path="/Faq/Faq-Create" small roundedPill />
                  <SearchBox
                    handleChange={(e) => {
                      handleFilterByQuery(e, ["heading"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={faqesArr && faqesArr.length > 0 ? faqesArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Faq;
