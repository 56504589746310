import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { GALLERYAdd, GALLERYUpdate, SetGALLERYObj } from "../../../redux/actions/Gallery/Gallery.actions";
import { toastError } from "../../Utility/ToastUtils";
import Select from "react-select";
import { CATEGORYGet } from "../../../redux/actions/Category/Category.actions";
import { LOCATIONGet } from "../../../redux/actions/Location/Location.actions";

function AddGallery() {
  const dispatch = useDispatch();
  const locationArrFromRedux = useSelector(
    (state) => state.location.locations
  );
  
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [isUpdateGallery, setIsUpdateGallery] = useState(false);
  const [selectedGalleryId, setselectedGalleryId] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [youtube, setyoutube] = useState("");
  const [status, setStatus] = useState(true);
  const [isMobile, setisMobile] = useState(false);
  const [locationObj, setLocationObj] = useState({label:"",value:""});
  const galleryObj = useSelector((state) => state.gallery.galleryObj);
  const [mainLocationArr, setMainLocationArr] = useState([]);

  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };


  useEffect(() => {
    dispatch(LOCATIONGet("level=1"));
  }, []);


  useEffect(() => {
    if (galleryObj) {
      setselectedGalleryId(galleryObj._id);
      setName(galleryObj.name);
      setDescription(galleryObj.description);
      setUrl(galleryObj.url);
      setselectedGalleryId(galleryObj._id);
      setImageStr(galleryObj.image);
      setisMobile(galleryObj.isMobile);
      setyoutube(galleryObj.youtube);
      
      setIsUpdateGallery(true);
    }
    return () => {
      dispatch(SetGALLERYObj(null));
    };
  }, [galleryObj]);


  useEffect(() => {
    if (locationArrFromRedux) {
      setMainLocationArr([...locationArrFromRedux]);
    }
  }, [locationArrFromRedux]);

  useEffect(() => {
    if (locationArrFromRedux?.length > 0  && galleryObj?.locationId) {
          let templocationObj = locationArrFromRedux.find((el) => `${el._id}` == `${galleryObj?.locationId}`);

          if(templocationObj && templocationObj?._id) {
            setLocationObj({label:templocationObj?.name,value:templocationObj?._id})
          }
    }
  }, [mainLocationArr,galleryObj]);
  const handleSubmit = () => {

    if (name == "") {
      toastError("Please Enter Name of Gallery");
      return 0
    }

    if (imageStr == "") {
      toastError("Please Upload Gallery image");
      return 0
    }
    let obj = {
      name,
      image: imageStr ,
      locationId:locationObj?.value
    };
    if (isUpdateGallery) {
      dispatch(GALLERYUpdate(obj, selectedGalleryId));
    } else {
      dispatch(GALLERYAdd(obj));
    }
  };
  return (
    <main>
      <section className="product-location">
        <div className="container-fluid p-0">
          <h5 className="main_text_color mb-4">{isUpdateGallery ? "Update" : "Add New "} Gallery</h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="main_text_color mb-4">Gallery Information</h5>
                    <div className="col-12">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>
                        Categories to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={locationObj}
                          onChange={(val) =>
                            setLocationObj(
                              val
                             
                            )
                          }
                          options={
                            mainLocationArr &&
                            mainLocationArr.length > 0 &&
                            mainLocationArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div> 
                  

                    
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label>Gallery (150x150)PX</label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                 
                    <div className="col-6">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={status}
                          onChange={() => setStatus(!status)}
                          className="form-check-input"
                          type="checkbox"
                          name="location-status"
                          value="option1"
                          id="active-gallery"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-gallery"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>

            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddGallery;
