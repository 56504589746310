import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill"; // ES6
import { addPage, getPageById, updatePage } from "../../services/page.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import QuillEditor from "../../utils/QuillEditor";

import { useSearchParams } from "react-router-dom";
function AddPage() {
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [isHtml, setIsHtml] = useState(false);

  const [PageObj, setPageObj] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = async () => {
    try {
      let obj = {
        title,
        description,
        isHtml,
        slug,
        image,
      };
      if (PageObj?._id) {
        let { data: res } = await updatePage(obj, PageObj?._id);
        if (res) {
          toastSuccess(res.message);
        }
      } else {
        let { data: res } = await addPage(obj);
        if (res) {
          toastSuccess(res.message);
        }
      }
      console.log(obj);
    } catch (error) {
      toastError(error);
    }
  };

  const handlegetPage = async () => {
    try {
      const { data: res } = await getPageById(searchParams.get("id"));
      if (res) {
        setPageObj(res?.data);
        setTitle(res?.data?.title);
        setDescription(res?.data?.description);
        setIsHtml(res?.data?.isHtml);
        setSlug(res?.data?.slug);
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      handlegetPage();
    }
  }, []);

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form className="form">
            <h5 className="main_text_color mb-4">{PageObj?._id ? "Update" : "Add"} New Post</h5>
            <div className="row">
              <div className="col-12 col-md-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="main_text_color mb-4">Post Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        TITLE <span className="red">*</span>
                      </label>
                      <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        SLUG<span className="red">*</span>
                      </label>
                      <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 mb-3">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="category-status" checked={isHtml} onChange={() => setIsHtml(!isHtml)} id="publish-checkbox" />
                        <label className="form-check-label fs-14" htmlFor="publish-checkbox">
                          Is HTML ?
                        </label>
                      </div>
                    </div>
                    {isHtml ? (
                      <div className="col-12">
                        <label>
                          DESCRIPTION<span className="red">*</span>
                        </label>
                        <textarea rows={10} value={description} onChange={(e) => setDescription(e.target.value)} type="text" className="form-control" />
                      </div>
                    ) : (
                      <div className="col-12">
                        <label>
                          DESCRIPTION<span className="red">*</span>
                        </label>
                        <QuillEditor theme="snow" handleChange={(e) => setDescription(e)} />
                      </div>
                    )}
                    <div className="col-12">
                      <CustomButton btntype="button" ClickEvent={() => handleSubmit()} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>

            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddPage;
