import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";

import { downloadSubsciber, getSubscriber } from "../../services/subscriber.service";
import { toastError } from "../Utility/ToastUtils";

function Subscriber() {

  let dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);
  const [subscriberArr, setSubscriberArr] = useState([]);
  const [query, setQuery] = useState("");
  const [qtype, setQType] = useState("");

  const handleBlogGet = async () => {
 
    try 
    {
       let query = ''
       const { data: res } = await getSubscriber(query);
       setBlogArr(res?.data);
       setSubscriberArr(res?.data);
   } catch (error) {
       console.error(error);
   }  
  };

  // console.log(handleBlogGet(), "erwerwe");
  useEffect(() => {
    handleBlogGet();
  }, []);


  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "20%",
    },

    {
      name: "Type",
      selector: (row) => row.queryType,
      width: "20%",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toDateString(),
      width: "20%",
    },

  ];

  const blog_data = [
    {
      id: "1",
      Seq: "1",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "2",
      Seq: "2",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "3",
      Seq: "3",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "4",
      Seq: "4",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "5",
      Seq: "5",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "6",
      Seq: "6",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "7",
      Seq: "7",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "8",
      Seq: "8",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "9",
      Seq: "9",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "10",
      Seq: "10",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
  ];


  useEffect(()=>{

    let tempArr = [...subscriberArr]


    if(query!=""){
      tempArr =   tempArr.filter((el) =>`${el['email']}`.toLowerCase().includes(`${query}`.toLowerCase()));
    }

    if(qtype!=""){
      tempArr =   tempArr.filter((el) =>`${el['queryType']}`.toLowerCase().includes(`${qtype}`.toLowerCase()));

    }
    setBlogArr([...tempArr]);

  },[qtype,query])





  const handleDownload =  async() => {
    try 
    {
       let que = `q=${query}&qtype=${qtype}`
       let url  = await downloadSubsciber(que);

       window.open(url)
return
   } catch (error) {
       console.error(error);
       toastError(error)
   }  
  }

  const handleChange = (e, requiredParametersArr) => {
    setQuery(e);

    let tempArr = subscriberArr.filter((el) => {
        if (`${el['email']}`.toLowerCase().includes(`${e}`.toLowerCase())) {
          return true;
        } else {
          return false;
        }
    });
    setBlogArr([...tempArr]);
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Subscriber List</h5>
                  

                <div className="search-field">

                    <form className="form">
                      <div className={ "input-group"}>
                        <div className="input-group-text">
                          <i className="ion-ios-search-strong blue-1"></i>
                        </div>
                        <input value={query} type="text" onChange={(e) =>  setQuery(e.target.value)} className="form-control" placeholder="Search" />
                      </div>
                    </form>
                  </div>
                  <div>
                    <select className="form-control" value={qtype} onChange={(e)=>setQType(e.target.value)}>
                      <option value="">Please Select Type</option>
                      <option value="Newsletter">Newsletter</option>
                      <option value="Offer">Offer</option>
                    </select>
                  </div>
                  <button className="btn btn-1 bg-black text-white rounded-pill btn-sm" type="button" onClick={()=>handleDownload()}><i className="fa fa-download"></i> Download</button>
    
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Subscriber;
