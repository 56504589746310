import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/ProductStock";

export const getStockBySellerId = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getStockBySellerId/${id}`);
};
export const getStocks = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getStocks?${query}`);
};

export const downloadSellerStockExcel = (obj) => {
  window.open(`${serverUrl}/downloadSellerStockExcel?data=${obj}`);
};

export const bulkStockUpload = (obj) => {
  return axiosApiInstance.post(`${serverUrl}/bulkStockUpload`, obj, { headers: { "Content-Type": "multipart/form-data" } });
};

export const updateStockManually = async (obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateStockManually`, obj);
};
