import React, { useEffect, useState } from "react";
import {
  getSystemSetting,
  updateSystemSetting,
} from "../../services/system.service";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

const Setting = () => {
  const [headerMarquee, setHeaderMarquee] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState(false);
  const [systemSetting, setSystemSetting] = useState([]);
  const [systemSettingId, setSystemSettingId] = useState("");
  const [activeTax, setactiveTax] = useState("GST");
  const [video, setvideo] = useState("");
  const [setionImage, setsetionImage] = useState("");
  const [sectionLink1, setsectionLink1] = useState("");
  const [sectionLink2, setsectionLink2] = useState("");
  const [companyInformation, setcompanyInformation] = useState("");
  const handleGetAllUsers = async () => {
    try {
      const { data: res } = await getSystemSetting();
      setSystemSetting(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetAllUsers();
  }, []);

  useEffect(() => {
    if (systemSetting) {
      setHeaderMarquee(systemSetting?.headerMarquee);
      setImage(systemSetting?.discountBanner);
      setCompanyName(systemSetting?.companyName);
      setEmail(systemSetting?.email);
      setImage(systemSetting?.logo);
      setPhoneNumber(systemSetting?.phoneNumber);
      setFacebookLink(systemSetting?.facebookLink);
      setInstagramLink(systemSetting?.instagramLink);
      setYoutubeLink(systemSetting?.youtubeLink);
      setLinkedinLink(systemSetting?.linkedinLink);
      setTwitterLink(systemSetting?.twitterLink);
      setAddress(systemSetting?.address);
      setStatus(systemSetting?.isDiscountActive);
      setSystemSettingId(systemSetting._id);
      setactiveTax(systemSetting?.activeTax)
      if(systemSetting?.section1?.image){
        setvideo(systemSetting?.section1?.image)
      }
      if(systemSetting?.section1?.link){
        setsectionLink1(systemSetting?.section1?.link)
      }

      if(systemSetting?.section2?.image){
        setsetionImage(systemSetting?.section2?.image)
      }

      if(systemSetting?.section2?.link){
        setsectionLink2(systemSetting?.section2?.link)
      }
    }
  }, [systemSetting]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let obj = {
      headerMarquee,
      companyName,
      email,
      image,
      companyInformation,
      phoneNumber,
      facebookLink,
      instagramLink,
      linkedinLink,
      twitterLink,
      address,
      youtubeLink,
      activeTax,
      discountBanner: image,
      isDiscountActive: status,
      section1:{
        image:video,
        link:sectionLink1
      },
      section2:{
        image:setionImage,
        link:sectionLink2
      }
    };
    console.log(obj, "homedecor2homedecor2homedecor2homedecor2");
    try {
      const { data: res } = await updateSystemSetting(systemSettingId, obj);
      toastSuccess(res?.message);

      handleGetAllUsers();
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <h5 className="main_text_color mb-4">Site Setting</h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Company Name<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Company name"
                      className="form-control"
                      value={companyName}
                      onChange={(event) => setCompanyName(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Email<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Phone<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Phone"
                      className="form-control"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Facebook Link <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={facebookLink}
                      onChange={(event) => setFacebookLink(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Instagram Link <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={instagramLink}
                      onChange={(event) => setInstagramLink(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Youtube Link <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={youtubeLink}
                      onChange={(event) => setYoutubeLink(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Twitter Link <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={twitterLink}
                      onChange={(event) => setTwitterLink(event.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Linkdin Link <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={linkedinLink}
                      onChange={(event) => setLinkedinLink(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="main_text_color fs-12">
                      Address <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label> Website Logo (1300x920)PX </label>
                    <img
                      src={
                        `${image}`.includes("base64")
                          ? image
                          : generateFilePath(image)
                      }
                      style={{ height: 80 }}
                    />
                    <FileUpload onFileChange={(val) => setImage(val)} />
                  </div>
                
                  <div className="col-md-12 mb-3">
                    <label className="main_text_color fs-12">
                      Footer Description <span className="red">*</span>
                    </label>
                    <textarea
                    rows={3}
                      placeholder="Title"
                      className="form-control"
                      value={companyInformation}
                      onChange={(event) => setcompanyInformation(event.target.value)}
                    ></textarea>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="main_text_color fs-12">
                      Header Heading <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={headerMarquee}
                      onChange={(event) => setHeaderMarquee(event.target.value)}
                    />
                  </div>

             
                
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>


                </form>
              </DashboardBox>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Setting;
