import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../../utils/toastUtils";
import { deletePage, getPage } from "../../services/page.service";
import moment from "moment";

function Page() {
  const [PageArr, setPageArr] = useState([]);

  const getAllPages = async () => {
    try {
      const { data: res } = await getPage();
      if (res) {
        setPageArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getAllPages();
  }, []);

  const handleDeletePage = async (id) => {
    try {
      console.log(id);
      const { data: res } = await deletePage(id);
      if (res) {
        getAllPages();
      }
    } catch (error) {
      toastError(error);
    }
  };

  const Page_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      width: "20%",
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
      width: "20%",
    },

    {
      name: "Published At",
      grow: 0,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "20%",
    },
    {
      name: "Description",
      grow: 0,
      cell: (row) => row?.description.slice(0, 100),
      width: "20%",
    },
    {
      name: "Action",
      cell: (row) => <ActionIcon remove deletePath={"/Page/page"} edit editPath={`/Page/page/create?id=${row?._id}`} onDeleteClick={() => handleDeletePage(row?._id)} isRedirected Uniquekey={row.id} />,
      width: "15%",
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="main_text_color m-0">Page List</h5>
                <div className="d-flex gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW Page" path="/Page/page/create" />
                  <SearchBox extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={Page_columns} data={PageArr} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Page;
